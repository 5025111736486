@tailwind utilities;

@layer utilities {
  .ultra-shadow {
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  }

  .ultra-card {
    @apply border border-stroke 
        rounded-lg
        ultra-shadow
        p-4
        bg-white;
  }

  .ultra-card p {
    color: #43474e;
  }

  .ultra-card:focus-visible {
    @apply border-stroke;
  }

  .ultra-link {
    @apply line-clamp-1 no-underline hover:underline hover:text-blue;
  }

  .transform-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
